import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UpdatePropertyUnitCost, PropertyUnitCost } from 'types/property-unit-cost';

import { useItemById } from 'hooks/useItemById';
import { useUpdateItem } from 'hooks/useUpdateItem';

import { FormTextField } from 'components/TextField';
import { FormDateField } from 'components/DateField';
import { FormSelectField } from 'components/SelectField';
import Switch from 'components/Switch';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyUnitCostUpdate() {
  const navigate = useNavigate();
  const { costId } = useParams();

  const { isLoading, data, error } = useItemById<PropertyUnitCost>('property-unit-cost', costId);

  const updateItem = useUpdateItem<UpdatePropertyUnitCost, PropertyUnitCost>('property-unit-cost', costId, {
    onSuccess: () => {
      toast.success('Saved!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    updateItem.reset();
    navigate('..');
  };

  const [repeats, setRepeats] = useState(false);

  useEffect(() => {
    setRepeats(!!data?.recurrent);
  }, [data]);

  const form: UpdatePropertyUnitCost = {
    name: data?.name || '',
    price: data?.price || '',
    payer: data?.payer || '',
    payee: data?.payee || '',
    happenedOn: data?.happenedOn || '',

    recurrent: data?.recurrent || 'month',
    recurrentEndOn: data?.recurrentEndOn || ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_unit_cost_update"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="UpdatePropertyUnitCost"
        error={updateItem.error?.message}
        onSubmit={async (values) => {
          const payload = {
            ...values,
            recurrent: repeats ? values.recurrent : null,
            recurrentEndOn: repeats && (values.recurrentEndOn || '').length ? values.recurrentEndOn : null
          };
          return updateItem.mutate(payload as UpdatePropertyUnitCost);
        }}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.save"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormTextField
          name="name"
          type="text"
          label="fields.name"
        />
        <FormTextField
          name="price"
          type="text"
          label="fields.price"
        />
        <FormTextField
          name="payer"
          type="text"
          label="fields.payer"
        />
        <FormTextField
          name="payee"
          type="text"
          label="fields.payee"
        />
        <FormDateField
          name="happenedOn"
          label="fields.happened_on"
        />
        <Switch
          id="repeats"
          name="repeats"
          label="fields.recurrent"
          value={repeats}
          onChange={(value) => setRepeats(value)}
          inForm
        />
        {repeats ? (
          <>
            <FormSelectField
              name="recurrent"
              label="fields.recurrent"
              optionsName="recurrent-type"
            />
            <FormDateField
              name="recurrentEndOn"
              label="fields.recurrent_end_on"
              clearable
            />
          </>
        ) : null}
      </Form>
    </Modal>
  );
}
