import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Tenant } from 'types/tenant';

import { useList } from 'hooks/useList';
import { useListFilter } from 'hooks/useListFilter';

import PageContainer from 'components/PageContainer';
import SectionHeader from 'components/SectionHeader';
import { ItemLink } from 'components/Item';

import SearchBar from 'components/SearchBar';
import Spacer from 'components/Spacer';
import List from 'components/List';
import Pagination from 'components/Pagination';

export default function Tenants() {
  const { t } = useTranslation();

  const {
    delayedSearch,
    search,
    setSearch
  } = useListFilter({});

  const list = useList<Tenant, void>('tenant.main', {
    search: delayedSearch
  });
  return (
    <PageContainer>
      <SectionHeader title="nav.tenants" />
      <SearchBar
        value={search}
        onChange={(value) => setSearch(value)}
      />
      <Spacer />
      <List
        isLoading={list.isLoading}
        error={list.error?.message}
        data={list.data}

        layout="vertical"

        item={({ item }) => (
          <ItemLink
              key={item.id}

              title={item.name}
              titleIcon="User"

              attributes={[
                ['License', t('text.count_agreements', { count: item.agreementsCount })]
              ]}

              to={item.id}
            />
        )}
      />
      <Spacer />
      <Pagination
        currentPage={list.pagination.currentPage}
        totalCount={list.pagination.totalCount}
        pageSize={list.pagination.pageSize}
        onPageChange={list.setCurrentPage}
      />
      <Outlet />
    </PageContainer>
  );
}
