import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


import { UpdatePropertyContact, PropertyContact } from 'types/property-contact';

import { useItemById } from 'hooks/useItemById';
import { useUpdateItem } from 'hooks/useUpdateItem';

import { FormTextField } from 'components/TextField';
import Button from 'components/Button';
import Form from 'components/Form';

import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyContactUpdate() {
  const navigate = useNavigate();
  const { contactId } = useParams();

  const { isLoading, data, error } = useItemById<PropertyContact>('property-contact', contactId);

  const update = useUpdateItem<UpdatePropertyContact, PropertyContact>('property-contact', contactId, {
    onSuccess: () => {
      toast.success('Saved!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    update.reset();
    navigate('..');
  };

  const form: UpdatePropertyContact = {
    name: data?.name || '',
    description: data?.description || '',
    email: data?.email || '',
    phone: data?.phone || ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_contact_update"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="UpdatePropertyContact"
        error={update.error?.message}
        onSubmit={async (values) => update.mutate(values as UpdatePropertyContact)}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.save"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormTextField
          name="name"
          type="text"
          label="fields.name"
        />
        <FormTextField
          name="description"
          type="multiline"
          label="fields.description"
        />
        <FormTextField
          name="email"
          type="text"
          label="fields.email"
        />
        <FormTextField
          name="phone"
          type="text"
          label="fields.phone"
        />
      </Form>
    </Modal>
  );
}
