import i18n from 'i18next';

export function recurrentTypeLabel(value?: string) {
  return value ? i18n.t(`fields.recurrent_type_${value}`) : 'unknown';
}

export function recurrentTypeList() {
  return [
    'month',
    'year'
  ].map((id) => ({
    id,
    name: recurrentTypeLabel(id)
  }));
}
