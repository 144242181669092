import { Rename } from 'types/file-manager';

import { FormTextField } from 'components/TextField';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';

interface Props {
  isOpen?: boolean;
  isLoading?: boolean;
  form: Rename;
  error?: string;
  onDismiss: () => void;
  onSubmit: (data: Rename) => Promise<void>;
}

export default function EntityRename(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      size="sm"
      onDismiss={() => props.onDismiss()}
    >
      <SectionHeader
        title="titles.file_manager_entity_rename"
        variant="dialog"
      />
      <Form
        initialValues={props.form}
        validationSchema="Rename"
        error={props.error}
        onSubmit={async (values) => props.onSubmit(values as Rename)}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.save"
            loading={props.isLoading || isSubmitting}
            disabled={props.isLoading || isSubmitting}
          />
        )}
      >
        <FormTextField
          name="name"
          type="text"
          label="fields.name"
        />
      </Form>
    </Modal>
  );
}
