import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// based on react-file-manager-ui

import {
  Node,
  NodeList,
  Feature,
  CollapsedList
} from './types';

import Actions from './Actions';
import SideBar from './SideBar';
import Body from './Body';
import FilePreview from './FilePreview';
import EntityDelete from './EntityDelete';
import EntityRename from './EntityRename';
import DirectoryCreate from './DirectoryCreate';

import styles from './FileManager.module.scss';

import SectionHeader from 'components/SectionHeader';
import NoData from 'components/NoData';



interface FileManagerProps {
  title: string;

  isLoading: boolean;
  doRefresh: () => Promise<void>;

  doClear: () => void;

  currentPath: string;
  setCurrentPath: (s: string) => void;

  structure: NodeList;

  createDirectory?: (path: string) => Promise<void>;
  isCreatingDirectory?: boolean;
  rename?: (path: string, name: string) => Promise<void>;
  isRenaming?: boolean;
  deletePaths?: (path: string[]) => Promise<void>;
  isDeleting?: boolean;

  uploadFiles?: (path: string, files: File[]) => Promise<void>;
  openFile: (file: Node) => Promise<void>;
  isOpening?: boolean;

  preview?: Node;
  setPreview: (n: Node | undefined) => void;
  previewUrl?: string;

  features?: Feature[];
}

export default function FileManager(props: FileManagerProps) {
  const [collapsed, setCollapsed] = useState<CollapsedList>({});
  const [selection, setSelection] = useState<string[]>([]);

  const [showCreateDirectory, setShowCreateDirectory] = useState(false);
  const [showRename, setShowRename] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { t } = useTranslation();

  const enabledFeatures = props.features || [];
  if (!props.features) {
    if (props.createDirectory) {
      enabledFeatures.push('createDirectory');
    }
    if (props.deletePaths) {
      enabledFeatures.push('deletePaths');
    }
    if (props.uploadFiles) {
      enabledFeatures.push('uploadFiles');
    }
    if (props.rename) {
      enabledFeatures.push('rename');
    }
  }

  const doUpload = async (currentPath: string, files: File[]) => {
    if(!props.uploadFiles) {
      return;
    }
    await props.uploadFiles(currentPath, Array.from(files));
  };

  const doCreateDirectory = async (name?: string) => {
    if(!props.createDirectory) {
      return;
    }

    if (!showCreateDirectory) {
      setShowCreateDirectory(true);
      return;
    }

    if (!name) {
      return;
    }

    await props.createDirectory(`${props.currentPath}/${name}`);
    setShowCreateDirectory(false);
  };

  const doRename = async (name?: string) => {
    if (!selection || !selection[0] || !props.rename) {
      return;
    }

    if (!showRename) {
      setShowRename(selection[0].split('/').pop() || '');
      return;
    }

    if (!name) {
      return;
    }

    try {
      await props.rename(selection[0], name);
      setShowRename('');
      props.doRefresh();
    } catch(error) {
      setShowRename('');
      // console.log(error);
      toast.error(t('toastr.error'));
    }
  };

  const doDeletePath = async () => {
    if (!selection || !props.deletePaths) {
      return;
    }

    if (!showDeleteConfirm) {
      setShowDeleteConfirm(true);
      return;
    }

    try {
      await props.deletePaths(selection);
      setSelection([]);
      setShowDeleteConfirm(false);
      props.doClear();
      props.doRefresh();
    } catch(error) {
      setShowDeleteConfirm(false);
      // console.log(error);
      toast.error(t('toastr.error'));
    }
  };

  useEffect(() => {
    props.doRefresh();
    setSelection([]);
  }, [props.currentPath]);

  const noData = !props.isLoading && (props.structure[Object.keys(props.structure)[0]] || []).length === 0;

  return (
    <div className={`${styles.base} ${props.isLoading ? styles.loading : ''}`}>
      <SectionHeader title={props.title}>
        {noData ? null : (
          <Actions
            size="base"

            currentPath={props.currentPath}
            setCurrentPath={props.setCurrentPath}

            selection={selection}

            doDeletePath={doDeletePath}
            doRename={doRename}
            doCreateDirectory={doCreateDirectory}
            doUpload={doUpload}
            doRefresh={props.doRefresh}

            enabledFeatures={enabledFeatures}
          />
        )}
      </SectionHeader>
      {noData ? (
        <NoData
          actionComponent={
            <Actions
              size="xs"

              currentPath={props.currentPath}
              setCurrentPath={props.setCurrentPath}

              selection={selection}

              doDeletePath={doDeletePath}
              doRename={doRename}
              doCreateDirectory={doCreateDirectory}
              doUpload={doUpload}
              doRefresh={props.doRefresh}

              enabledFeatures={enabledFeatures}
            />
          }
        />
      ) : (
        <div className={styles.middle}>
          <SideBar
            structure={props.structure}

            currentPath={props.currentPath}
            setCurrentPath={props.setCurrentPath}

            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <Body
            structure={props.structure}
            loading={props.isLoading}

            currentPath={props.currentPath}
            setCurrentPath={props.setCurrentPath}

            doRename={doRename}
            doUpload={doUpload}
            doOpenFile={props.openFile}

            selection={selection}
            setSelection={setSelection}

            enabledFeatures={enabledFeatures}
          />
        </div>
      )}
      {showCreateDirectory ? (
        <DirectoryCreate
          isOpen={showCreateDirectory}
          isLoading={props.isCreatingDirectory}
          onDismiss={() => setShowCreateDirectory(false)}
          onSubmit={(data) => doCreateDirectory(data.name)}
        />
      ) : null}
      {showRename ? (
        <EntityRename
          isOpen={!!showRename.length}
          isLoading={props.isRenaming}
          onDismiss={() => setShowRename('')}
          form={{ name: showRename }}
          onSubmit={(data) => doRename(data.name)}
        />
      ) : null}
      {showDeleteConfirm ? (
        <EntityDelete
          isOpen={showDeleteConfirm}
          isLoading={props.isDeleting}
          onDismiss={() => setShowDeleteConfirm(false)}
          onSubmit={() => doDeletePath()}
        />
      ) : null}
      {props.preview ? (
        <FilePreview
          isOpen={true}
          isLoading={props.isOpening}
          onDismiss={() => props.setPreview(undefined)}
          file={props.preview}
          url={props.previewUrl}
          doDownload={props.openFile}
        />
      ) : null}
    </div>
  );
}