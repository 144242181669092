import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Property } from 'types/property';
import { CreatePropertyUnit, PropertyUnit } from 'types/property-unit';

import { useItemById } from 'hooks/useItemById';
import { useCreateItem } from 'hooks/useCreateItem';

import { FormTextField } from 'components/TextField';
import { FormSelectField } from 'components/SelectField';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyUnitCreate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading, error } = useItemById<Property>('property', id);

  const createItem = useCreateItem<CreatePropertyUnit, PropertyUnit>('property-unit', {
    onSuccess: () => {
      toast.success('Contact added!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    createItem.reset();
    navigate('..');
  };

  const form: CreatePropertyUnit = {
    propertyId: id || 'nope',

    name: '',
    type: 'apartment',
    description: '',
    bedrooms: '',
    bathrooms: '',
    area: '',
    floor: ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_unit_create"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="CreatePropertyUnit"
        error={createItem.error?.message}
        onSubmit={async (values) => createItem.mutate(values as CreatePropertyUnit)}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.create"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormTextField
          name="name"
          type="text"
          label="fields.name"
        />
        <FormTextField
          name="description"
          type="multiline"
          label="fields.description"
        />
        <FormSelectField
          name="tags"
          label="fields.tags"
          multi
          optionsName="property-unit-tag"
          optionsPayload={{}}
          allowCreate={{}}
        />
        <FormTextField
          name="bedrooms"
          type="text"
          label="fields.bedrooms"
        />
        <FormTextField
          name="bathrooms"
          type="text"
          label="fields.bathrooms"
        />
        <FormTextField
          name="area"
          type="text"
          label="fields.area"
        />
        <FormTextField
          name="floor"
          type="text"
          label="fields.floor"
        />
      </Form>
    </Modal>
  );
}
