import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { CreateChatConversation, ChatConversation } from 'types/chat';
import { Tenant } from 'types/tenant';

import { useCreateItem } from 'hooks/useCreateItem';
import { useList } from 'hooks/useList';

import Text from 'components/Text';
import Button from 'components/Button';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';
import Spinner from 'components/Spinner';
import NoData from 'components/NoData';
import DamnLayout from 'components/DamnLayout';


export default function MessagesConversationCreate() {
  const navigate = useNavigate();
  const { conversationId } = useParams();

  const { t } = useTranslation();

  const list = useList<Tenant, any>('tenant.new_conversation');

  const createItem = useCreateItem<CreateChatConversation, ChatConversation>('chat/conversation', {
    onSuccess: (data) => {
      toast.success('Conversation created!');
      dismiss(conversationId ? `../../${data?.data?.id}` : `../${data?.data?.id}`);
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = (destination?: string) => {
    createItem.reset();
    navigate(destination || '..');
  };

  const theList = list.isLoading ? (
    <Spinner size="xl" />
  ) : list.error ? (
    <Text>
      An error has occurred: {list.error.message}
    </Text>
  ) : list.data && !list.data?.length ? (
    <NoData />
  ) : list.data && list.data ? (
    <DamnLayout variant="vertical">
      {list.data.map((item) => (
          <Button
            key={item.id}
            variant="gray-light"
            size="xl"
            icon="User"
            iconColor="primary"
            title={item.name}
            onPress={() => createItem.mutate({ recipientId: item.id })}
            disabled={!item.hasAccount || createItem.isLoading}
          />
      ))}
    </DamnLayout>
  ) : null;

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={() => dismiss()}
    >
      <SectionHeader
        title="titles.messages_conversation_create"
        variant="dialog"
      />
      {theList || null}
      {createItem.isLoading ? (
        <Spinner />
      ) : null}
    </Modal>
  );
}
