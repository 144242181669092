import { useRef, ChangeEvent } from 'react';

import {
  Feature
} from '../types';

import styles from './Actions.module.scss';

import Button from 'components/Button';

interface Props {
  size: 'xs' | 'sm' | 'base';

  currentPath: string;
  setCurrentPath: (n: string) => void;

  selection?: string[];

  doDeletePath: () => void;
  doRename: () => void;
  doUpload: (path: string, files: File[]) => void;
  doCreateDirectory: (path: string) => void;
  doRefresh: () => void;

  enabledFeatures: Feature[];
}

export default function TopBar(props: Props) {
  const {
    size,

    currentPath,
    setCurrentPath,

    selection,

    doDeletePath,
    doRename,
    doUpload,
    doCreateDirectory,
    doRefresh,

    enabledFeatures
  } = props;

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if(!files) {
      return;
    }

    doUpload(currentPath, Array.from(files));
  };

  return (
    <div className={styles.base}>
      {currentPath ? (
        <>
          <Button
            variant="gray-light"
            size={size}
            icon="Home"
            onPress={() => setCurrentPath('')}
          />
          <Button
            variant="gray-light"
            size={size}
            icon="CornerLeftUp"
            onPress={() => {
              const parts = currentPath.split('/');
              parts.splice(parts.length - 1, 1);
              setCurrentPath(parts.join('/'));
            }}
          />
          <Button
            variant="gray-light"
            size={size}
            icon="Refresh"
            onPress={() => doRefresh()}
          />
        </>
      ) : null}
      {selection && selection.length === 1 && enabledFeatures.includes('rename') ? (
        <Button
          variant="gray-light"
          size={size}
          icon="Pencil"
          onPress={() => doRename()}
        />
      ) : null}
      {selection && selection.length === 1 && enabledFeatures.includes('deletePaths') ? (
        <Button
          variant="gray-light"
          size={size}
          icon="Trash"
          iconColor="danger"
          onPress={() => doDeletePath()}
        />
      ) : null}
      {enabledFeatures.includes('createDirectory') ? (
        <Button
          variant="gray-light"
          size={size}
          icon="FolderPlus"
          onPress={() => doCreateDirectory(currentPath)}
        />
      ) : null}
      {enabledFeatures.includes('uploadFiles') ? (
        <>
          <input
            ref={uploadInputRef}
            type="file"
            onChange={onFileSelect}
            hidden
          />
          <Button
            variant="gray-light"
            size={size}
            icon="Upload"
            iconColor="primary"
            title="buttons.upload"
            onPress={() => uploadInputRef.current && uploadInputRef.current?.click()}
          />
        </>
      ) : null}
    </div>
  );
}
