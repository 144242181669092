import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { UpdateProperty, Property } from 'types/property';
import { PropertyUnit } from 'types/property-unit';
import { PropertyContact } from 'types/property-contact';
import { StatsMonthly } from 'types/stats';

import { formatMoney } from 'utils/money';
import { countryLabel } from 'utils/country';

import { useAuth } from 'hooks/useAuth';
import { useList } from 'hooks/useList';
import { useUpdateItem } from 'hooks/useUpdateItem';
import { useDeleteItem } from 'hooks/useDeleteItem';

import Text from 'components/Text';
import Button, { ButtonLink } from 'components/Button';
import ConfirmButton from 'components/ConfirmButton';
import WaitForIt from 'components/WaitForIt';
import PageContainer from 'components/PageContainer';
import SectionHeader from 'components/SectionHeader';
import Item, { ItemLink } from 'components/Item';
import { useItemById } from 'hooks/useItemById';
import DamnLayout from 'components/DamnLayout';
import List from 'components/List';
import {
  ChartOccupancy,
  ChartRevenue
} from 'components/Chart';
import Spacer from 'components/Spacer';

interface FloorMap {
  [key: string]: PropertyUnit[] | undefined;
}

export default function PropertyPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const { user } = useAuth();

  const { isLoading, data, error } = useItemById<Property>('property', id, {
    onError: () => {
      navigate('..');
    }
  });

  const update = useUpdateItem<UpdateProperty, Property>('property', id, {
    onSuccess: () => {
      toast.success('Saved!');
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const deleteProperty = useDeleteItem('property');

  const userId = data?.userId;
  const asTenant = user && user.id !== userId;
  const isOwner = !!(userId && !asTenant);

  const statsMonthlyList = useList<StatsMonthly, any>('stats/monthly', {
    filter: { propertyId: id }
  });

  const unitList = useList<PropertyUnit, any>('property-unit', {
    filter: { propertyId: id }
  });

  const shouldGroupUnitsByFloor = (unitList.data || []).length > 10;

  const unitsByFloor = shouldGroupUnitsByFloor ? unitList.data?.reduce<FloorMap>((acc, unit) => {
    const floor = unit.floor ? `${unit.floor}` : '-';

    const theFloor = acc[floor] || [];
    acc[floor] = theFloor;

    theFloor.push(unit);

    return acc;
  }, {}) : undefined;

  const contactList = useList<PropertyContact, any>('property-contact', {
    filter: { propertyId: id }
  });

  const deleteContact = useDeleteItem('property-contact');

  return (
    <PageContainer>
      <SectionHeader
        title={data ? data.name : 'Property'}
        to="/properties"
      >
        {isOwner ? (
          <>
            <ButtonLink
              variant="gray-light"
              icon="Pencil"
              iconColor="primary"
              to="update"
              title="buttons.edit"
            />
            {data?.deactivatedAt ? (
              <ConfirmButton
                variant="danger-light"
                icon="Trash"
                iconColor="danger"
                title="buttons.delete"
                confirmTitle="titles.property_delete"
                message="confirmations.property_delete"
                confirmVariant="danger"
                confirmLabel="buttons.delete"
                loading={deleteProperty.isLoading}
                disabled={deleteProperty.isLoading}
                onPress={() => id && deleteProperty.mutate(id)}
              />
            ) : null}
            {data?.deactivatedAt ? (
              <Button
                variant="success-light"
                loading={update.isLoading}
                disabled={update.isLoading}
                onPress={() => {
                  if (update.isLoading) {
                    return;
                  }
                  update.mutate({ deactivatedAt: null });
                }}
                title="buttons.reactivate"
              />
            ) : (
              <Button
                variant="danger-light"
                loading={update.isLoading}
                disabled={update.isLoading}
                onPress={() => {
                  if (update.isLoading) {
                    return;
                  }
                  update.mutate({ deactivatedAt: 'now' });
                }}
                title="buttons.deactivate"
              />
            )}
          </>
        ) : null}
      </SectionHeader>
      <WaitForIt
        isLoading={isLoading}
        error={error}
        data={data}
      >
        <Item
          title={isOwner ? data?.type === 'full' ? 'Complete ownership' : 'Partial ownership' : 'External'}
          titleIcon={data?.type === 'full' ? 'BuildingCommunity' : 'Building'}

          description={[
            data?.addressStreet,
            `${data?.addressPostalCode} ${data?.addressCity}`,
            countryLabel(data?.addressCountry)
          ]}

          // attributes={isOwner ? [
          //   ['Home', t('text.count_units', { count: unitList.data?.length || 0 })],
          //   ['CurrencyEuro', formatMoney(`${unitList.data?.reduce((acc, item) => acc + parseFloat(item.currentAgreement?.price || '0'), 0)}`)]
          // ] : undefined}

          // photo="some-photo"

          isDeactivated={!!data?.deactivatedAt}
          isExternal={!isOwner}
        />
      </WaitForIt>
      <Spacer />
      <DamnLayout variant="propertyStats">
          <ChartRevenue
            data={statsMonthlyList.data}
            isLoading={statsMonthlyList.isLoading}
            error={statsMonthlyList.error}
          />
          <ChartOccupancy
            data={statsMonthlyList.data}
            isLoading={statsMonthlyList.isLoading}
            error={statsMonthlyList.error}
          />
      </DamnLayout>

      <SectionHeader title="titles.property_units">
        {(unitList?.data && !unitList.data?.length) || !isOwner ? null : (
          <ButtonLink
            variant="gray-light"
            icon="Plus"
            iconColor="primary"
            to="unit-create"
            title="buttons.add"
          />
        )}
      </SectionHeader>

      {unitsByFloor ? (
        <DamnLayout variant="vertical">
          {Object.keys(unitsByFloor).sort().map((floor) => (
            <div key={floor}>
              <Text variant="group-title">
                {t('titles.property_unit_floor', { floor })}
              </Text>
              <Spacer size="sm" />
              <List
                isLoading={unitList.isLoading}
                error={unitList.error?.message}
                data={unitsByFloor[floor]}

                createTo="unit-create"

                layout="propertyUnitFloor"

                item={({ item }) => (
                  <ItemLink
                    key={item.id}

                    title={item.name}
                    titleIcon={item.type === 'commercial' ? 'BuildingStore' : 'Home'}

                    attributes={item.currentAgreement ? [
                      ['User', item.currentAgreement.tenant],
                      ['CurrencyEuro', formatMoney(item.currentAgreement.price)],
                    ] : [
                      ['User', '-'],
                      ['CurrencyEuro', '-']
                    ]}

                    isDeactivated={!!item.deactivatedAt}

                    to={`/units/${item.id}`}
                  />
                )}
              />
            </div>
          ))}
        </DamnLayout>
      ) : (
        <List
          isLoading={unitList.isLoading}
          error={unitList.error?.message}
          data={unitList.data}

          createTo="unit-create"

          layout="propertyUnitFloor"

          item={({ item }) => (
            <ItemLink
              key={item.id}

              title={item.name}
              titleIcon={item.type === 'commercial' ? 'BuildingStore' : 'Home'}

              attributes={item.currentAgreement ? [
                ['User', item.currentAgreement.tenant],
                ['CurrencyEuro', formatMoney(item.currentAgreement.price)],
              ] : [
                ['User', '-'],
                ['CurrencyEuro', '-']
              ]}

              isDeactivated={!!item.deactivatedAt}

              to={`/units/${item.id}`}
            />
          )}
        />
      )}

      <SectionHeader title="titles.property_contacts">
        {(contactList?.data && !contactList.data?.length) || !isOwner ? null : (
          <ButtonLink
            variant="gray-light"
            icon="Plus"
            iconColor="primary"
            to="contact-create"
            title="buttons.add"
          />
        )}
      </SectionHeader>

      <List
        isLoading={contactList.isLoading}
        error={contactList.error?.message}
        data={contactList.data}

        createTo="contact-create"

        layout="propertyContacts"

        item={({ item }) => (
          <Item
            key={item.id}

            variant="secondary"

            title={item.name}
            titleIcon="User"

            description={[
              item.description
            ]}

            attributes={[
              ['Phone', item.phone],
              ['Mail', item.email]
            ]}

            actions={isOwner ? (
              <>
                <ButtonLink
                  icon="Pencil"
                  iconColor="primary"
                  to={`contact-update/${item.id}`}
                />
                <ConfirmButton
                  icon="Trash"
                  iconColor="danger"
                  confirmTitle="titles.property_contact_delete"
                  message="confirmations.property_contact_delete"
                  confirmVariant="danger"
                  confirmLabel="buttons.delete"
                  loading={deleteContact.isLoading}
                  disabled={deleteContact.isLoading}
                  onPress={() => deleteContact.mutate(item.id)}
                />
              </>
            ) : null}
          />
        )}
      />

      <Outlet />
    </PageContainer>
  );
}
