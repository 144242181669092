import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Property } from 'types/property';
import { CreatePropertyContact, PropertyContact } from 'types/property-contact';

import { useItemById } from 'hooks/useItemById';
import { useCreateItem } from 'hooks/useCreateItem';

import { FormTextField } from 'components/TextField';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyContactCreate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading, error } = useItemById<Property>('property', id);

  const createItem = useCreateItem<CreatePropertyContact, PropertyContact>('property-contact', {
    onSuccess: () => {
      toast.success('Contact added!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    createItem.reset();
    navigate('..');
  };

  const form: CreatePropertyContact = {
    propertyId: id || 'nope',

    name: '',
    description: '',
    email: '',
    phone: ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_contact_create"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="CreatePropertyContact"
        error={createItem.error?.message}
        onSubmit={async (values) => createItem.mutate(values as CreatePropertyContact)}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.create"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormTextField
          name="name"
          type="text"
          label="fields.name"
        />
        <FormTextField
          name="description"
          type="multiline"
          label="fields.description"
        />
        <FormTextField
          name="email"
          type="text"
          label="fields.email"
        />
        <FormTextField
          name="phone"
          type="text"
          label="fields.phone"
        />
      </Form>
    </Modal>
  );
}
