import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PropertyUnit } from 'types/property-unit';
import { CreatePropertyUnitCost, PropertyUnitCost } from 'types/property-unit-cost';

import { useItemById } from 'hooks/useItemById';
import { useCreateItem } from 'hooks/useCreateItem';

import { FormTextField } from 'components/TextField';
import { FormDateField } from 'components/DateField';
import { FormSelectField } from 'components/SelectField';
import Switch from 'components/Switch';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyUnitCostCreate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading, error } = useItemById<PropertyUnit>('property-unit', id);

  const createItem = useCreateItem<CreatePropertyUnitCost, PropertyUnitCost>('property-unit-cost', {
    onSuccess: () => {
      toast.success('Cost added!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    createItem.reset();
    navigate('..');
  };

  const [repeats, setRepeats] = useState(false);

  const form = {
    propertyUnitId: id || 'nope',

    name: '',
    price: '',
    payer: '',
    payee: '',
    happenedOn: '',

    recurrent: 'month'
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_unit_cost_create"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="CreatePropertyUnitCost"
        error={createItem.error?.message}
        onSubmit={async (values) => {
          const payload = {
            ...values,
            recurrent: repeats ? values.recurrent : undefined,
            recurrentEndOn: repeats ? values.recurrentEndOn : undefined
          };
          return createItem.mutate(payload as CreatePropertyUnitCost);
        }}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.create"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormTextField
          name="name"
          type="text"
          label="fields.name"
        />
        <FormTextField
          name="price"
          type="text"
          label="fields.price"
        />
        <FormTextField
          name="payer"
          type="text"
          label="fields.payer"
        />
        <FormTextField
          name="payee"
          type="text"
          label="fields.payee"
        />
        <FormDateField
          name="happenedOn"
          label="fields.happened_on"
        />
        <Switch
          id="repeats"
          name="repeats"
          label="fields.recurrent"
          value={repeats}
          onChange={(value) => setRepeats(value)}
          inForm
        />
        {repeats ? (
          <>
            <FormSelectField
              name="recurrent"
              label="fields.recurrent"
              optionsName="recurrent-type"
            />
            <FormDateField
              name="recurrentEndOn"
              label="fields.recurrent_end_on"
              clearable
            />
          </>
        ) : null}
      </Form>
    </Modal>
  );
}
