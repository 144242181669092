import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Socket } from 'socket.io-client';

import { manager } from 'services/socket';

import { useAuth } from 'hooks/useAuth';
import { getToken } from '../utils/tokenCookies';

export function useSocketConnection() {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    const io = manager.socket('/', {
      auth: {
        token: getToken()
      }
    });

    setSocket(io);

    function onConnect() {
      console.log('Connected');
    }
    function onDisconnect() {
      console.log('Disconnected');
    }
    // function onEvent(data: any) {
    //   console.log('event', data);
    //   // normal stuff to invalidate...
    //   // const data = JSON.parse(event.data);
    //   // const queryKey = [...data.entity, data.id].filter(Boolean);
    //   // queryClient.invalidateQueries({ queryKey });

    //   // sample for direct partial updates of the data...
    //   // queryClient.setQueriesData(data.entity, (oldData) => {
    //   //   const update = (entity) => entity.id === data.id ? { ...entity, ...data.payload } : entity;
    //   //   return Array.isArray(oldData) ? oldData.map(update) : update(oldData);
    //   // });
    // }
    io.on('connect', onConnect);
    io.on('disconnect', onDisconnect);

    io.on('chatMessageCreated', (data) => {
      // console.log('chatMessageCreated shit', data);
      queryClient.refetchQueries(['list', 'chat/message', { conversationId: data.conversationId }]);
    });

    return () => {
      io.close();
      io.off('connect', onConnect);
      io.off('disconnect', onDisconnect);
      io.off('chatMessageCreated');
    };

  }, [user]);


  // useEffect(() => {

  //   function onConnect() {
  //     console.log('Connected');
  //   }
  //   function onDisconnect() {
  //     console.log('Disconnected');
  //   }
  //   function onEvent(data: any) {
  //     console.log('event', data);
  //     // normal stuff to invalidate...
  //     // const data = JSON.parse(event.data);
  //     // const queryKey = [...data.entity, data.id].filter(Boolean);
  //     // queryClient.invalidateQueries({ queryKey });

  //     // sample for direct partial updates of the data...
  //     // queryClient.setQueriesData(data.entity, (oldData) => {
  //     //   const update = (entity) => entity.id === data.id ? { ...entity, ...data.payload } : entity;
  //     //   return Array.isArray(oldData) ? oldData.map(update) : update(oldData);
  //     // });
  //   }
  //   socket.on('connect', onConnect);
  //   socket.on('event', onEvent);
  //   socket.on('exception', (data) => {
  //     console.log('exception', data);
  //   });
  //   socket.on('error', (data) => {
  //     console.log('error', data);
  //   });
  //   socket.on('disconnect', onDisconnect);


  //   socket.connect();

  //   return () => {
  //     socket.off('connect', onConnect);
  //     socket.off('disconnect', onDisconnect);
  //     socket.off('event', onEvent);
  //     socket.off('exception', onEvent);
  //   };
  // }, [queryClient]);

  // useEffect(() => {
  //   console.log('user changed', user);

  //   // socket.auth.token = getToken();

  //   if (user && !socket.connected) {
  //     socket.connect();
  //   }

  //   return () => {
  //     if (!socket.connected) {
  //       return;
  //     }
  //     socket.disconnect();
  //   };
  // }, [user]);
}
