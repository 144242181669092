import {
  useNavigate,
  useParams,
  Outlet
} from 'react-router-dom';

import { PropertyUnitAgreement } from 'types/property-unit-agreement';

import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

import { useItemById } from 'hooks/useItemById';
import { useDeleteItem } from 'hooks/useDeleteItem';

import { ButtonLink } from 'components/Button';
import Drawer from 'components/Drawer';
import SectionHeader from 'components/SectionHeader';
import Text from 'components/Text';
import ConfirmButton from 'components/ConfirmButton';


export default function PropertyUnitAgreementDetail() {
  const navigate = useNavigate();
  const { agreementId } = useParams();

  const { isLoading, data, error } = useItemById<PropertyUnitAgreement>('property-unit-agreement', agreementId);

  const dismiss = () => {
    navigate('..');
  };

  const deleteAgreement = useDeleteItem('property-unit-agreement');

  return (
    <Drawer
      isOpen={true}
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title={data ? `${formatDate(data.startingOn)} - ${data.endingOn ? formatDate(data.endingOn) : 'OPEN'}` : 'loading'}
        variant="dialog"
      >
        <ButtonLink
          variant="gray-light"
          icon="Pencil"
          iconColor="primary"
          to="update"
        />
        <ConfirmButton
          variant="danger-light"
          icon="Trash"
          iconColor="danger"
          title="buttons.delete"
          confirmTitle="titles.property_unit_agreement_delete"
          message="confirmations.property_unit_agreement_delete"
          confirmVariant="danger"
          confirmLabel="buttons.delete"
          loading={deleteAgreement.isLoading}
          disabled={deleteAgreement.isLoading}
          onPress={() => agreementId && deleteAgreement.mutate(agreementId)}
        />
      </SectionHeader>

      <Text>
        {data?.tenant}
      </Text>
      <Text>
        {formatMoney(data?.price)}
      </Text>

      <SectionHeader title="titles.property_unit_agreement_invoices" />
      <Text>
        invoices area here...
      </Text>

      <Outlet />
    </Drawer>
  );
}
