
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import { Node } from '../types';

import styles from './FilePreview.module.scss';

interface Props {
  isOpen?: boolean;
  isLoading?: boolean;
  error?: string;
  onDismiss: () => void;
  file: Node;
  url?: string;
  doDownload: (file: Node) => void;
}

export default function EntityRename(props: Props) {
  const isImage = (props.file.mimetype || '').startsWith('image/');
  const isObject = props.file.mimetype === 'application/pdf';

  return (
    <Modal
      isOpen={props.isOpen}
      size={isObject ? 'lg' : 'base'}
      variant={isObject ? 'full-height' : undefined}
      onDismiss={() => props.onDismiss()}
    >
      <SectionHeader
        title="titles.file_manager_file_preview"
        variant="dialog"
      >
        <Button
          variant="gray-light"
          icon="Download"
          iconColor="primary"
          title="buttons.download"
          onPress={() => props.doDownload(props.file)}
        />
      </SectionHeader>
      <div className={styles.base}>
        {isObject && props.url?.length ? (
          <object
            className={styles.isObject}
            data={props.url}
            type="application/pdf"
          />
        ) : null}

        {isImage && props.url?.length ? (
          <div className={styles.isImage}>
            <img
              src={props.url}
              alt="preview"
            />
          </div>
        ) : null}

        {!props.error && !props.url?.length ? (
          <div className={styles.loadingContainer}>
            <Spinner />
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
