import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const countries = [
  'de',
  'dk',
  'es',
  'fr',
  'pt',
  'pl',
  'ro'
];

export function useCountriesList() {
  const { t } = useTranslation();
  
  const data = useMemo(() => countries.map((a2) => ({ value: a2, label: t(`country_names.${a2}`) })), [t]);

  return data;
}
