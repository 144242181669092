import {
  useNavigate,
  useParams,
  Outlet
} from 'react-router-dom';

import {
  ColumnDef
} from '@tanstack/react-table';

import { formatDateTime } from 'utils/date';

import { PropertyUnitMeter } from 'types/property-unit-meter';
import { PropertyUnitMeterReading } from 'types/property-unit-meter-reading';

import { useItemById } from 'hooks/useItemById';
import { useDeleteItem } from 'hooks/useDeleteItem';
import { useList } from 'hooks/useList';

import { ButtonLink } from 'components/Button';
import ConfirmButton from 'components/ConfirmButton';
import Drawer from 'components/Drawer';
import Table from 'components/Table';
import SectionHeader from 'components/SectionHeader';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import Cell from 'components/Cell';


function ReadingActions(props: { data: PropertyUnitMeterReading; }) {
  const id = props.data.id;
  const deleteReading = useDeleteItem(`property-unit-meter-reading.${id}`);
  return (
    <Cell variant="actions">
      <ButtonLink
        icon="Pencil"
        iconColor="primary"
        size="xs"
        to={`reading-update/${id}`}
      />
      <ConfirmButton
        icon="Trash"
        iconColor="danger"
        size="xs"
        confirmTitle="titles.property_unit_meter_reading_delete"
        message="confirmations.property_unit_meter_reading_delete"
        confirmVariant="danger"
        confirmLabel="buttons.delete"
        loading={deleteReading.isLoading}
        disabled={deleteReading.isLoading}
        onPress={() => deleteReading.mutate(id)}
      />
    </Cell>
  );
}

const columns: ColumnDef<PropertyUnitMeterReading>[] = [
  {
    id: 'happenedOn',
    accessorFn: (row) => row.happenedOn,
    header: 'fields.happened_on',
    enableSorting: true,
    size: 170,
    cell: (info) => (<Cell>{formatDateTime(info.getValue<string>())}</Cell>)
  },
  {
    id: 'value',
    accessorFn: (row) => row.value,
    header: 'fields.value',
    enableSorting: true,
    cell: (info) => (<Cell>{info.getValue<string>()}</Cell>)
  },
  {
    id: 'agent',
    accessorFn: (row) => row.agent,
    header: 'fields.agent',
    enableSorting: true,
    cell: (info) => (<Cell>{info.getValue<string>()}</Cell>)
  },
  {
    id: 'reading-actions',
    accessorFn: (row) => row.id,
    header: '',
    size: 1,
    cell: (info) => (<ReadingActions data={info.row.original} />)
  }
];

export default function PropertyUnitMeterDetail() {
  const navigate = useNavigate();
  const { meterId } = useParams();

  const { isLoading, data, error } = useItemById<PropertyUnitMeter>('property-unit-meter', meterId);

  const dismiss = () => {
    navigate('..');
  };

  const list = useList<PropertyUnitMeterReading, any>('property-unit-meter-reading', {
    enabled: !!data?.name,
    filter: {
      propertyUnitMeterId: meterId
    },
    sort: [{ id: 'happenedOn', desc: true }]
  });

  const deleteMeter = useDeleteItem('property-unit-agreement');

  return (
    <Drawer
      isOpen={true}
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title={data?.name || 'loading'}
        variant="dialog"
      >
        <ButtonLink
          variant="gray-light"
          icon="Pencil"
          iconColor="primary"
          to="update"
        />
        <ConfirmButton
          variant="danger-light"
          icon="Trash"
          iconColor="danger"
          title="buttons.delete"
          confirmTitle="titles.property_unit_meter_delete"
          message="confirmations.property_unit_meter_delete"
          confirmVariant="danger"
          confirmLabel="buttons.delete"
          loading={deleteMeter.isLoading}
          disabled={deleteMeter.isLoading}
          onPress={() => meterId && deleteMeter.mutate(meterId)}
        />
      </SectionHeader>
      <Table
        columns={columns}
        selectable={false}
        exportable={true}
        toggleable={true}

        data={list.data}
        isLoading={list.isLoading}
        error={list.error?.message}

        sortable={true}
        sorting={list.sorting}
        onSortingChange={list.setSorting}
      />
      <Spacer />
      <Pagination
        currentPage={list.pagination.currentPage}
        totalCount={list.pagination.totalCount}
        pageSize={list.pagination.pageSize}
        onPageChange={list.setCurrentPage}
      />
      <Outlet />
    </Drawer>
  );
}
