import {
  NodeList,
  TreeNode,
  CollapsedList
} from '../types';


import SideBarTreeNode from '../SideBarTreeNode';

import styles from './SideBar.module.scss';

interface Props {
  structure: NodeList;

  currentPath: string;
  setCurrentPath: (path: string) => void;

  collapsed: CollapsedList;
  setCollapsed: (n: CollapsedList) => void;
}

export default function SideBar(props: Props) {
  const tree: TreeNode[] = [];
  const nodesByPath: { [key: string]: TreeNode } = {};

  Object.keys(props.structure || {}).forEach((path) => {
    const parts = path.split('/');
    let tmpPath = '';
    let childNodes: TreeNode[] = tree;

    parts.forEach((part, index) => {
      tmpPath += (index > 0 ? '/' : '') + part;

      if (!nodesByPath[tmpPath]) {
        const node: TreeNode = {
          name: part,
          path: tmpPath,
          children: []
        };
        childNodes.push(node);
        nodesByPath[tmpPath] = node;
      }

      childNodes = nodesByPath[tmpPath].children;

      if (index === (parts.length - 1)) {
        const children = (props.structure[tmpPath] || []).filter((item) => item.type === 2).map((item) => ({
          name: item.name,
          path: `${tmpPath}/${item.name}`,
          children: []
        }));

        children.forEach((item) => {
          if (!childNodes.find((node) => node.name === item.name)) {
            childNodes.push(item);
            nodesByPath[`${tmpPath}/${item.name}`] = item;
          }
        });
      }
    });
  });

  if (!tree[0] || tree[0].children.length < 4) {
    return null;
  }

  return (
    <div className={styles.base}>
      <SideBarTreeNode
        node={tree[0] || { name: '', path: '/', children: [] }}
        level={0}

        structure={props.structure}

        currentPath={props.currentPath}
        setCurrentPath={props.setCurrentPath}

        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
      />
    </div>
  );
}

