import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  Node,
  NodeList
} from 'components/FileManager/types';
import { fetchListOf, fsAction, ItemResponse } from 'services/api';
import { useFSAction } from 'hooks/useFSAction';
import { triggerDownload } from 'utils/file';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

interface CreateDirectory {
  entityId: string;
  path: string;
}
interface Directory {
  entityId: string;
  path: string;
}

interface RenameEntity {
  entityId: string;
  path: string;
  name: string;
}
interface Rename {
  entityId: string;
  path: string;
  name: string;
}

interface DeleteEntity {
  entityId: string;
  paths: string[];
}
interface Delete {
  entityId: string;
  paths: string[];
}

interface UploadFile {
  entityId: string;
  path: string;
  file: File;
}

interface Upload {
  entityId: string;
  path: string;
  file: File;
}

interface CreateFileLink {
  entityId: string;
  path: string;
}

interface FileLink {
  url: string;
}

// fs/contents/list

// fs/directory/create
// fs/entity/rename
// fs/entity/delete

// fs/file/upload
// fs/file/link/ { entityId, path }
// fs/file/download/:id || fs/file/download/:id?preview=true


async function loadPath(entityId: string, path: string) {
  try {
    const response = await fetchListOf<Node>('fs/contents', { entityId, path });
    return response?.data ?? [];
  } catch (error) {
    console.error(error);
  }
}

export function completeLink(id?: string, preview?: boolean) {
  const prev = '?preview=true';
  return id ? `/api/fs/file/download/${id}${preview ? prev : ''}` : undefined;
}

export function useFS(entityId: string) {
  const { t } = useTranslation();

  const [structure, setStructure] = useState<NodeList>({});
  const [currentPath, setCurrentPath] = useState('');
  const [lastPath, setLastPath] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [preview, setPreview] = useState<Node | undefined>(undefined);

  const doRefresh = async () => {
    setIsLoading(true);
    const updated: NodeList = {};
    const notChanged: NodeList = {};

    try {
      const paths = Object.keys(structure).filter((path) => {
        if (currentPath.indexOf(path) === 0 || path.indexOf(currentPath) === 0) {
          return true;
        } else {
          notChanged[path] = structure[path];
          return false;
        }
      });
      if (paths.indexOf(currentPath) === -1) {
        paths.push(currentPath);
      }
      await Promise.all(paths.map((path) => {
        const promise = loadPath(entityId, path);
        promise.then((list) => {
          if (list !== undefined) {
            updated[path] = list;
            if (path === currentPath) {
              setLastPath(currentPath);
            }
          }
        }).catch(console.error);
        return promise;
      }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setCurrentPath(lastPath);
    }
    const processed = { ...notChanged, ...updated };
    const ordered: NodeList = {};
    Object.keys(processed).sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    }).forEach((path) => {
      ordered[path] = processed[path];
    });
    setStructure(ordered);
  };

  const doClear = () => {
    setStructure({});
  };

  const doCreateDirectory = useFSAction<CreateDirectory, Directory>('directory/create', entityId, {
    onSuccess() {
      doRefresh();
    },
    onError() {
      toast.error(t('toastr.error'));
    }
  });
  const doRename = useFSAction<RenameEntity, Rename>('entity/rename', entityId, {
    onSuccess() {
      doRefresh();
    },
    onError() {
      toast.error(t('toastr.error'));
    }
  });
  const doDelete = useFSAction<DeleteEntity, Delete>('entity/delete', entityId, {
    onSuccess() {
      toast.success(t('toastr.success'));
      doRefresh();
    },
    onError() {
      toast.error(t('toastr.error'));
    }
  });

  const doUpload = useFSAction<UploadFile, Upload>('file/upload', entityId, {
    asFormData: true,
    onUploadProgress(procentCompleted) {
      console.log('doUpload', procentCompleted);
    },
    onSuccess() {
      toast.success(t('toastr.success'));
      doRefresh();
    },
    onError() {
      toast.error(t('toastr.error'));
    }
  });

  const doDownload = useFSAction<CreateFileLink, FileLink>('file/link', entityId, {
    onSuccess(data, payload) {
      const url = data?.data?.url;
      if(!url) {
        return;
      }

      const theUrl = completeLink(url);

      if (!theUrl) {
        return;
      }

      const fileName = payload.path.split('/').pop();
      triggerDownload(theUrl, fileName);
    },
    onError() {
      toast.error(t('toastr.error'));
    }
  });

  const doPreviewUrl = useQuery<ItemResponse<FileLink>, AxiosError>({
    queryKey: ['fs', { entityId, path: preview?.path }],
    queryFn: () => fsAction<FileLink, CreateFileLink>('file/link', { entityId, path: preview?.path || 'aloha' }),
    enabled: !!preview,
    staleTime: 1000 * 10
  });

  return {
    createDirectory: async (path: string) => {
      return doCreateDirectory.mutate({
        entityId,
        path
      });
    },
    rename: async (path: string, name: string) => {
      return doRename.mutate({
        entityId,
        path,
        name
      });
    },
    deletePaths: async (paths: string[]) => {
      return doDelete.mutate({
        entityId,
        paths
      });
    },
    uploadFiles: async (path: string, files: File[]) => {
      return doUpload.mutate({
        entityId,
        path,
        file: files[0]
      });
    },
    openFile: async (file: Node) => {
      const isImage = (file.mimetype || '').startsWith('image/');
      const isPdf = file.mimetype === 'application/pdf';

      if (file !== preview && (isImage || isPdf)) {
        setPreview(file);
        return;
      }

      return doDownload.mutate({
        entityId,
        path: file.path
      });
    },

    isLoading,
    structure,
    currentPath,
    setCurrentPath,
    lastPath,

    doRefresh,
    doClear,

    preview,
    setPreview,
    previewUrl: completeLink(doPreviewUrl.data?.data?.url, true),

    isCreatingDirectory: doCreateDirectory.isLoading,
    isRenaming: doRename.isLoading,
    isDeleting: doDelete.isLoading,
    isUploading: doUpload.isLoading,
    isOpening: doDownload.isLoading
  };
}
