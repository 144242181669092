import { Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ChatConversation, ChatMessage, CreateChatMessage } from 'types/chat';

import { formatName } from 'utils/user';

import { useAuth } from 'hooks/useAuth';
import { useList } from 'hooks/useList';
import { useItemById } from 'hooks/useItemById';
import { useCreateItem } from 'hooks/useCreateItem';

import {
  Avatar as ChatAvatar,
  MainContainer,
  Conversation,
  Sidebar,
  ChatContainer,
  ConversationHeader,
  MessageList,
  Message,
  MessageInput
} from '@chatscope/chat-ui-kit-react';

import Avatar from 'components/Avatar';
import { ButtonLink } from 'components/Button';
import PageContainer from 'components/PageContainer';
import SectionHeader from 'components/SectionHeader';


// look at: https://chatscope.io/demo/

export default function Messages() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { conversationId } = useParams();
  const { t } = useTranslation();

  const conversations = useList<ChatConversation, any>('chat/conversation');

  const activeConversation = useItemById<ChatConversation>('chat/conversation', conversationId, {
    onError: () => {
      navigate('.');
    }
  });
  const messages = useList<ChatMessage, any>('chat/message', {
    enabled: !!conversationId,
    filter: {
      conversationId
    },
    pageSize: 500
  });

  const createMessage = useCreateItem<CreateChatMessage, ChatMessage>('chat/message', {
    noListsRefetch: true,
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const receiver = user && activeConversation.data?.participants.find((participant) => participant && participant.id !== user.id);
  const name = formatName(receiver, '??');


  return (
    <PageContainer>
      <SectionHeader title="nav.messages">
        <ButtonLink
          variant="gray-light"
          icon="Plus"
          iconColor="primary"
          to={`${location.pathname}/create`}
          title="buttons.new_conversation"
        />
      </SectionHeader>

      <div style={{ position:"relative", height: "60vh" }}>
        <MainContainer responsive>
          <Sidebar
            position="left"
            loading={conversations.isLoading}

            scrollable={false}
          >
            {conversations.data?.map((conversation) => {
              const receiver = user && conversation.participants.find((participant) => participant && participant.id !== user.id);
              const name = formatName(receiver, '??');

              return (
                <Conversation
                  key={conversation.id}

                  active={conversationId === conversation.id}

                  onClick={() => navigate(conversation.id)}
                >
                  <ChatAvatar name={name}>
                    <Avatar
                      id={receiver?.avatar}
                      alt={name}
                      variant="fluid"
                    />
                  </ChatAvatar>
                  <Conversation.Content
                    name={name}
                  />
                </Conversation>
              );
            })}
          </Sidebar>
          {activeConversation.data ? (
            <ChatContainer>
              <ConversationHeader>
                <ConversationHeader.Back />
                <ChatAvatar name={name}>
                  <Avatar
                    id={receiver?.avatar}
                    alt={name}
                    variant="fluid"
                  />
                </ChatAvatar>
                <ConversationHeader.Content userName={name} />
              </ConversationHeader>
              <MessageList
                loading={messages.data ? false : messages.isLoading}
              >
                {(messages.data || []).map((message, index, col) => (
                  <Message
                    key={message.id}
                    model={{
                      message: message.content,
                      sentTime: message.createdAt,
                      sender: formatName(message.sender, '?'),
                      direction: message.sender?.id === user?.id ? 'outgoing' : 'incoming',
                      position: index === 0 ? 'first' : index === col.length - 1 ? 'last' : 'normal'
                    }}
                  />
                ))}
              </MessageList>
              <MessageInput
                placeholder={t('placeholders.chat_message')}

                attachButton={false}

                disabled={createMessage.isLoading}

                onSend={(innerHtml, innerText) => {
                  if (!conversationId || !innerHtml.length || !innerText.length) {
                    return;
                  }

                  createMessage.mutate({
                    conversationId,
                    content: innerText
                  });
                }}
              />
            </ChatContainer>
          ) : null}
        </MainContainer>
      </div>
      <Outlet />
    </PageContainer>
  );
}



