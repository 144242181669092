import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { UpdatePropertyUnit, PropertyUnit } from 'types/property-unit';

import { useItemById } from 'hooks/useItemById';
import { useUpdateItem } from 'hooks/useUpdateItem';

import Text from 'components/Text';
import { FormSelectField } from 'components/SelectField';
import { FormTextField } from 'components/TextField';
import Button from 'components/Button';
import Form from 'components/Form';
import Spacer from 'components/Spacer';
import Modal from 'components/Modal';


export default function PropertyUnitUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const { isLoading, data, error } = useItemById<PropertyUnit>('property-unit', id);

  const update = useUpdateItem<UpdatePropertyUnit, PropertyUnit>('property-unit', id, {
    onSuccess: () => {
      toast.success('Saved!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    update.reset();
    navigate('..');
  };

  const form: UpdatePropertyUnit = {
    name: data?.name || '',
    description: data?.description || '',
    tags: (data?.tags || []).map((tag) => tag.id),
    bedrooms: data?.bedrooms || '',
    bathrooms: data?.bathrooms || '',
    area: data?.area || '',
    floor: data?.floor || ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <Text variant="title">
        {t('titles.property_unit_update')}
      </Text>
      <Spacer size="xl" />
        <Form
          initialValues={form}
          validationSchema="UpdatePropertyUnit"
          error={update.error?.message}
          onSubmit={async (values) => update.mutate(values as UpdatePropertyUnit)}
          submitElement={(isSubmitting) => (
            <Button
              type="submit"
              variant="primary"
              size="xl"
              title="buttons.save"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          )}
        >
          <FormTextField
            name="name"
            type="text"
            label="fields.name"
          />
          <FormTextField
            name="description"
            type="multiline"
            label="fields.description"
          />
          <FormSelectField
            name="tags"
            label="fields.tags"
            multi
            optionsName="property-unit-tag"
            optionsPayload={{}}
            allowCreate={{}}
          />
          <FormTextField
            name="bedrooms"
            type="text"
            label="fields.bedrooms"
          />
          <FormTextField
            name="bathrooms"
            type="text"
            label="fields.bathrooms"
          />
          <FormTextField
            name="area"
            type="text"
            label="fields.area"
          />
          <FormTextField
            name="floor"
            type="text"
            label="fields.floor"
          />
        </Form>
    </Modal>
  );
}
