import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { fsAction, ItemResponse } from 'services/api';

interface Options<D, T> {
  asFormData?: boolean;
  onSuccess?: (data: ItemResponse<T>, variables: D) => void;
  onUploadProgress?: (procentCompleted: number) => void;
  onError?: (error: AxiosError, variables: D) => void;
}

export function useFSAction<D, T>(key: string, id: string, options?: Options<D, T>) {
  const queryClient = useQueryClient();

  // const [smallKey, extra] = key.split('.');

  return useMutation<ItemResponse<T>, AxiosError, D>(['fs', key, { id }], (data: D) => fsAction<T, D>(key, data, { asFormData: options?.asFormData, onUploadProgress: options?.onUploadProgress }), {
    onSuccess: (data, variables) => {
      // queryClient.refetchQueries(['list', key], { active: true });
      // queryClient.refetchQueries(['list', smallKey], { active: true });

      if (!options || !options.onSuccess) {
        return;
      }
      options.onSuccess(data, variables);
    },
    onError: (error, variables) => {
      if (!options || !options.onError) {
        return;
      }

      if (error.response) {
        const responseData = error.response.data as { message?: string; };

        error.message = responseData.message ? responseData.message : error.message;
      }
      options.onError(error, variables);
    }
  });
}
