import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UpdatePropertyUnitMeter, PropertyUnitMeter } from 'types/property-unit-meter';

import { useItemById } from 'hooks/useItemById';
import { useUpdateItem } from 'hooks/useUpdateItem';

import { FormTextField } from 'components/TextField';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyUnitMeterUpdate() {
  const navigate = useNavigate();
  const { meterId } = useParams();

  const { isLoading, data, error } = useItemById<PropertyUnitMeter>('property-unit-meter', meterId);

  const update = useUpdateItem<UpdatePropertyUnitMeter, PropertyUnitMeter>('property-unit-meter', meterId, {
    onSuccess: () => {
      toast.success('Saved!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    update.reset();
    navigate('..');
  };

  const form: UpdatePropertyUnitMeter = {
    name: data?.name || '',
    unit: data?.unit || ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_unit_meter_update"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="UpdatePropertyUnitMeter"
        error={update.error?.message}
        onSubmit={async (values) => update.mutate(values as UpdatePropertyUnitMeter)}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.save"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormTextField
          name="name"
          type="text"
          label="fields.name"
        />
        <FormTextField
          name="unit"
          type="text"
          label="fields.unit"
        />
      </Form>
    </Modal>
  );
}
