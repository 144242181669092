import { useTranslation } from 'react-i18next';

import {
  NodeList,
  TreeNode,
  CollapsedList
} from '../types';

import Folder from 'components/icons/Folder';

import styles from './SideBarTreeNode.module.scss';

interface Props {
  node: TreeNode,
  level: number;

  structure: NodeList;

  currentPath: string;
  setCurrentPath: (n: string) => void;

  collapsed: CollapsedList;
  setCollapsed: (n: CollapsedList) => void;
}

const OFFSET = 16;

export default function SideBarTreeNode({ node, structure, currentPath, setCurrentPath, collapsed, setCollapsed, level }: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.base}>
      <div
        className={`${styles.name} ${currentPath === node.path ? styles.current : ''}`}

        onClick={() => {
          setCollapsed({
            ...collapsed,
            [node.path]: currentPath === node.path ? !collapsed[node.path] : false
          });

          setCurrentPath(node.path);
        }}
        style={{ paddingLeft: 8 + (level || 0) * OFFSET + 'px' }}
      >
        <Folder className={!collapsed[node.path] && structure[node.path] ? styles.iconOpen : styles.iconCollapsed} />
        {node.name || t('buttons.home')}
      </div>
      {!!node.children && !!node.children.length && !collapsed[node.path] ? (
        <div className={styles.children}>
          {node.children.map((item, index) => (
            <SideBarTreeNode
              key={index}

              node={item}
              level={level + 1}
              structure={structure}

              currentPath={currentPath}
              setCurrentPath={setCurrentPath}

              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}
