import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet
} from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import { useSocketConnection } from 'hooks/useSocketConnection';

import LoadingPage from 'components/LoadingPage';
import MainNavAside from 'components/MainNavAside';
// import UserMenuLayout from 'components/UserMenuLayout';
import PageDividedLayout from 'components/PageDividedLayout';

import Login from 'pages/Login';
import Register from 'pages/Register';
import Recover from 'pages/Recover';
import PasswordUpdate from 'pages/PasswordUpdate';
import Activate from 'pages/Activate';
import EmailUpdate from 'pages/EmailUpdate';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Messages from 'pages/Messages';
import MessagesConversationCreate from 'pages/MessagesConversationCreate';
import Account from 'pages/Account';
import AccountUpdate from 'pages/AccountUpdate';
import AccountAvatarUpdate from 'pages/AccountAvatarUpdate';
import AccountEmailUpdate from 'pages/AccountEmailUpdate';
import AccountPasswordUpdate from 'pages/AccountPasswordUpdate';
import Properties from 'pages/Properties';
import Property from 'pages/Property';
import PropertyCreate from 'pages/PropertyCreate';
import PropertyUpdate from 'pages/PropertyUpdate';
import PropertyUnit from 'pages/PropertyUnit';
import PropertyUnitCreate from 'pages/PropertyUnitCreate';
import PropertyUnitUpdate from 'pages/PropertyUnitUpdate';
import PropertyUnitMeterDetail from 'pages/PropertyUnitMeterDetail';
import PropertyUnitMeterUpdate from 'pages/PropertyUnitMeterUpdate';
import PropertyUnitMeterReadingCreate from 'pages/PropertyUnitMeterReadingCreate';
import PropertyUnitMeterReadingUpdate from 'pages/PropertyUnitMeterReadingUpdate';
import PropertyUnitAgreementDetail from 'pages/PropertyUnitAgreementDetail';
import PropertyUnitAgreementCreate from 'pages/PropertyUnitAgreementCreate';
import PropertyUnitAgreementUpdate from 'pages/PropertyUnitAgreementUpdate';
import PropertyUnitCostCreate from 'pages/PropertyUnitCostCreate';
import PropertyUnitCostUpdate from 'pages/PropertyUnitCostUpdate';
import PropertyContactCreate from 'pages/PropertyContactCreate';
import PropertyContactUpdate from 'pages/PropertyContactUpdate';
import Tenants from 'pages/Tenants';
import TenantDetail from 'pages/TenantDetail';
import NotFound from 'pages/NotFound';

function RequireAuth({ children }: { children: JSX.Element }) {
  const { user } = useAuth();
  const location = useLocation();

  if(!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

let prevPath = '';

function ScrollToTop() { // should apply only to the main nav stuff ...
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname === '/' ? '/home' : location.pathname;

    if (path === prevPath) {
      return;
    }

    const count = (path.match(/\//g) || []).length;

    const isTopLevel = count === 1;
    const isFromOwnSub = prevPath.indexOf(path) === 0;

    if (isTopLevel && !isFromOwnSub) {
      window.scrollTo(0, 0);
    }

    prevPath = path;

  }, [location.pathname]);

  return null;
}

function WebSocketConnection() {
  useSocketConnection();
  return null;
}

function RoutesList() {
  const { loading } = useAuth();

  if (loading) {
    return (<LoadingPage />);
  }

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <>
                <WebSocketConnection />
                <PageDividedLayout
                  aside={
                    <>
                      <MainNavAside />
                    </>
                  }
                >
                  <Outlet />
                </PageDividedLayout>
              </>
            </RequireAuth>
          }
        >
          <Route
            index
            element={<Navigate to="/properties" replace />}
          />
          <Route
            path="messages"
            element={<Messages />}
          >
            <Route
              path=":conversationId"
              element={<Outlet />}
            >
              <Route
                path="create"
                element={<MessagesConversationCreate />}
              />
            </Route>
            <Route
              path="create"
              element={<MessagesConversationCreate />}
            />
          </Route>
          <Route
            path="properties"
            element={<Properties />}
          >
            <Route
              path="create"
              element={<PropertyCreate />}
            />
          </Route>
          <Route
            path="properties/:id"
            element={<Property />}
          >
            <Route
              path="update"
              element={<PropertyUpdate />}
            />
            <Route
              path="unit-create"
              element={<PropertyUnitCreate />}
            />
            <Route
              path="contact-create"
              element={<PropertyContactCreate />}
            />
            <Route
              path="contact-update/:contactId"
              element={<PropertyContactUpdate />}
            />
          </Route>
          <Route
            path="units/:id"
            element={<PropertyUnit />}
          >
            <Route
              path="update"
              element={<PropertyUnitUpdate />}
            />
            <Route
              path="meter/:meterId"
              element={<PropertyUnitMeterDetail />}
            >
              <Route
                path="update"
                element={<PropertyUnitMeterUpdate />}
              />
              <Route
                path="reading-update/:readingId"
                element={<PropertyUnitMeterReadingUpdate />}
              />
            </Route>
            <Route
              path="meter-reading-create"
              element={<PropertyUnitMeterReadingCreate />}
            />
            <Route
              path="agreement/:agreementId"
              element={<PropertyUnitAgreementDetail />}
            >
              <Route
                path="update"
                element={<PropertyUnitAgreementUpdate />}
              />
            </Route>
            <Route
              path="agreement-create"
              element={<PropertyUnitAgreementCreate />}
            />
            <Route
              path="cost-create"
              element={<PropertyUnitCostCreate />}
            />
            <Route
              path="cost-update/:costId"
              element={<PropertyUnitCostUpdate />}
            />
          </Route>
          <Route
            path="tenants"
            element={<Tenants />}
          >
            <Route
              path=":tenantId"
              element={<TenantDetail />}
            />
          </Route>
          <Route
            path="account"
            element={<Account />}
          >
            <Route
              path="update"
              element={<AccountUpdate />}
            />
            <Route
              path="avatar-update"
              element={<AccountAvatarUpdate />}
            />
            <Route
              path="email-update"
              element={<AccountEmailUpdate />}
            />
            <Route
              path="password-update"
              element={<AccountPasswordUpdate />}
            />
          </Route>
        </Route>

        <Route
          path="login"
          element={<Login />}
        />
        <Route
          path="register"
          element={<Register />}
        />
        <Route
          path="recover"
          element={<Recover />}
        />
        <Route
          path="password-update/:token"
          element={<PasswordUpdate />}
        />
        <Route
          path="activate/:token"
          element={<Activate />}
        />
        <Route
          path="email-update/:token"
          element={<EmailUpdate />}
        />
        <Route
          path="privacy-policy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
    </Router>
  );
}

export default RoutesList;
