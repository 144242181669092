import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UpdatePropertyUnitAgreement, PropertyUnitAgreement } from 'types/property-unit-agreement';

import { useItemById } from 'hooks/useItemById';
import { useUpdateItem } from 'hooks/useUpdateItem';

import { FormTextField } from 'components/TextField';
import { FormSwitch } from 'components/Switch';
import { FormDateField } from 'components/DateField';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyUnitAgreementUpdate() {
  const navigate = useNavigate();
  const { agreementId } = useParams();

  const { isLoading, data, error } = useItemById<PropertyUnitAgreement>('property-unit-agreement', agreementId);

  const update = useUpdateItem<UpdatePropertyUnitAgreement, PropertyUnitAgreement>('property-unit-agreement', agreementId, {
    onSuccess: () => {
      toast.success('Saved!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    update.reset();
    navigate('..');
  };

  const form: UpdatePropertyUnitAgreement = {
    tenant: data?.tenant || '',
    allowTenantAccess: data?.allowTenantAccess || false,
    price: data?.price || '',
    startingOn: data?.startingOn || '',
    endingOn: data?.endingOn || ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_unit_agreement_update"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="UpdatePropertyUnitAgreement"
        error={update.error?.message}
        onSubmit={async (values) => update.mutate(values as UpdatePropertyUnitAgreement)}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.save"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormTextField
          name="tenant"
          type="text"
          label="fields.tenant"
        />
        <FormSwitch
          name="allowTenantAccess"
          label="fields.allow_tenant_access"
        />
        <FormTextField
          name="price"
          type="text"
          label="fields.price"
        />
        <FormDateField
          name="startingOn"
          label="fields.starting_on"
        />
        <FormDateField
          name="endingOn"
          label="fields.ending_on"
          clearable
        />
      </Form>
    </Modal>
  );
}
