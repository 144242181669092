import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { UpdatePropertyUnit, PropertyUnit } from 'types/property-unit';
import { PropertyUnitMeter } from 'types/property-unit-meter';
import { PropertyUnitCost } from 'types/property-unit-cost';
import { PropertyUnitAgreement } from 'types/property-unit-agreement';

import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { formatNumber } from 'utils/misc';

import { useAuth } from 'hooks/useAuth';
import { useList } from 'hooks/useList';
import { useUpdateItem } from 'hooks/useUpdateItem';
import { useDeleteItem } from 'hooks/useDeleteItem';
import { useFS } from 'hooks/useFS';

import Button, { ButtonLink } from 'components/Button';
import ConfirmButton from 'components/ConfirmButton';
import WaitForIt from 'components/WaitForIt';
import PageContainer from 'components/PageContainer';
import SectionHeader from 'components/SectionHeader';
import Item, { ItemLink } from 'components/Item';
import { useItemById } from 'hooks/useItemById';
import * as icons from 'components/icons';
import FileManager from 'components/FileManager';
import List from 'components/List';


const meterIcon: { [propName: string]: keyof typeof icons } = {
  gas: 'Flame',
  electricity: 'Plug',
  water: 'DropletFilled2'
};

export default function PropertyUnitPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const { user } = useAuth();

  const { isLoading, data, error } = useItemById<PropertyUnit>('property-unit', id, {
    onError: () => {
      navigate('..');
    }
  });

  const update = useUpdateItem<UpdatePropertyUnit, PropertyUnit>('property-unit', id, {
    onSuccess: () => {
      toast.success('Saved!');
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const deletePropertyUnit = useDeleteItem('property-unit');

  const userId = data?.userId;
  const asTenant = user && user.id !== userId;
  const isOwner = !!(userId && !asTenant);

  const fs = useFS(id || 'aloha');

  const meterList = useList<PropertyUnitMeter, any>('property-unit-meter', {
    enabled: isOwner,
    filter:  { propertyUnitId: id }
  });

  const agreementList = useList<PropertyUnitAgreement, any>('property-unit-agreement', {
    filter: { propertyUnitId: id }
  });

  const costList = useList<PropertyUnitCost, any>('property-unit-cost', {
    enabled: isOwner,
    filter: { propertyUnitId: id }
  });

  const deleteCost = useDeleteItem('property-unit-cost');

  return (
    <PageContainer>
      <SectionHeader
        title={data ? data.name : 'Unit'}
        to={data ? `/properties/${data.propertyId}` : '/properties'}
      >
        {isOwner ? (
          <>
            <ButtonLink
              icon="Pencil"
              iconColor="primary"
              to="update"
              title="buttons.edit"
            />
            {data?.deactivatedAt ? (
              <ConfirmButton
                variant="danger-light"
                icon="Trash"
                iconColor="danger"
                title="buttons.delete"
                confirmTitle="titles.property_unit_delete"
                message="confirmations.property_unit_delete"
                confirmVariant="danger"
                confirmLabel="buttons.delete"
                loading={deletePropertyUnit.isLoading}
                disabled={deletePropertyUnit.isLoading}
                onPress={() => id && deletePropertyUnit.mutate(id)}
              />
            ) : null}
            {data?.deactivatedAt ? (
              <Button
                variant="success-light"
                loading={update.isLoading}
                disabled={update.isLoading}
                onPress={() => {
                  if (update.isLoading) {
                    return;
                  }
                  update.mutate({ deactivatedAt: null });
                }}
                title="buttons.reactivate"
              />
            ) : (
              <Button
                variant="danger-light"
                loading={update.isLoading}
                disabled={update.isLoading}
                onPress={() => {
                  if (update.isLoading) {
                    return;
                  }
                  update.mutate({ deactivatedAt: 'now' });
                }}
                title="buttons.deactivate"
              />
            )}
          </>
        ) : null}
      </SectionHeader>
      <WaitForIt
        isLoading={isLoading}
        error={error}
        data={data}
      >
        <Item
          title={data?.type === 'commercial' ? 'Commercial' : 'Apartment'}
          titleIcon={data?.type === 'commercial' ? 'BuildingStore' : 'Home'}

          description={[
            data?.description
          ]}

          tags={(data?.tags || []).map((tag) => tag.name)}

          attributes={[
            ['Bed', t('text.count_bedrooms', { count: formatNumber(data?.bedrooms) })],
            ['Bath', t('text.count_bathrooms', { count: formatNumber(data?.bathrooms) })],
            ['Frame', `${formatNumber(data?.area)} m2`],
            ['Stairs', `${data?.floor || 0} ${t('fields.floor')}`]
          ]}

          isDeactivated={!!data?.deactivatedAt}
          isExternal={!isOwner}
        />
      </WaitForIt>

      {isOwner ? (
        <>
          <SectionHeader title="titles.property_unit_meters">
            {meterList?.data && !meterList.data?.length ? null : (
              <ButtonLink
                variant="gray-light"
                icon="Plus"
                iconColor="primary"
                to="meter-reading-create"
                title="buttons.add"
              />
            )}
          </SectionHeader>
          <List
            isLoading={meterList.isLoading}
            error={meterList.error?.message}
            data={meterList.data}

            createTo="meter-reading-create"

            layout="propertyUnitMeters"

            item={({ item }) => (
              <ItemLink
                key={item.id}

                title={item.name}
                titleIcon={meterIcon[item.name.toLowerCase()] ? meterIcon[item.name.toLowerCase()] : 'ScaleOutline'}

                description={[item.latestReading ? `${formatDate(item.latestReading.happenedOn)}: ${item.latestReading.value}` : '-']}

                to={`meter/${item.id}`}
              />
            )}
          />
          <SectionHeader title="titles.property_unit_agreements">
            {agreementList?.data && !agreementList.data?.length ? null : (
              <ButtonLink
                variant="gray-light"
                icon="Plus"
                iconColor="primary"
                to="agreement-create"
                title="buttons.add"
              />
            )}
          </SectionHeader>
          <List
            isLoading={agreementList.isLoading}
            error={agreementList.error?.message}
            data={agreementList.data}

            createTo="agreement-create"

            layout="vertical"

            item={({ item }) => (
              <ItemLink
                key={item.id}

                to={`agreement/${item.id}`}

                title={`${formatDate(item.startingOn)} - ${item.endingOn ? formatDate(item.endingOn) : 'OPEN'}`}
                titleIcon="License"

                attributes={[
                  ['User', item.tenant],
                  ['CurrencyEuro', formatMoney(item.price)]
                ]}

                isOld={item.startingOn && item.endingOn && (new Date(item.endingOn).valueOf() < new Date().valueOf()) ? true : false}
              />
            )}
          />
          <FileManager
            title="titles.property_unit_documents"
            {...fs}
          />
          <SectionHeader title="titles.property_unit_costs">
            {costList?.data && !costList.data?.length ? null : (
              <ButtonLink
                variant="gray-light"
                icon="Plus"
                iconColor="primary"
                to="cost-create"
                title="buttons.add"
              />
            )}
          </SectionHeader>
          <List
            isLoading={costList.isLoading}
            error={costList.error?.message}
            data={costList.data}

            createTo="cost-create"

            layout="vertical"

            item={({ item }) => (
              <Item
                key={item.id}

                title={item.name}
                titleIcon="Receipt"
                subtitle={formatDate(item.happenedOn)}

                attributes={[
                  ['CurrencyEuro', formatMoney(item.price)],
                  ['User', item.payer],
                  ['UserCheck', item.payee],
                  ['CalendarRepeat', item.recurrent ? item.recurrent === 'year' ? t('fields.recurrent_type_year') : t('fields.recurrent_type_month') : t('fields.recurrent_type_one_time')]
                ]}

                actions={(
                  <>
                    <ButtonLink
                      icon="Pencil"
                      iconColor="primary"
                      to={`cost-update/${item.id}`}
                    />
                    <ConfirmButton
                      icon="Trash"
                      iconColor="danger"
                      confirmTitle="titles.property_unit_cost_delete"
                      message="confirmations.property_unit_cost_delete"
                      confirmVariant="danger"
                      confirmLabel="buttons.delete"
                      loading={deleteCost.isLoading}
                      disabled={deleteCost.isLoading}
                      onPress={() => deleteCost.mutate(item.id)}
                    />
                  </>
                )}
              />
            )}
          />
        </>
      ) : (
        <>
          <SectionHeader title="titles.property_unit_agreements" />
          <List
            isLoading={agreementList.isLoading}
            error={agreementList.error?.message}
            data={agreementList.data}

            layout="vertical"

            item={({ item }) => (
              <Item
                key={item.id}

                title={`${formatDate(item.startingOn)} - ${formatDate(item.endingOn) || 'OPEN'}`}
                titleIcon="License"

                attributes={[
                  ['User', item.tenant],
                  ['CurrencyEuro', formatMoney(item.price)]
                ]}

                isOld={item.startingOn && item.endingOn && (new Date(item.endingOn).valueOf() < new Date().valueOf()) ? true : false}
              />
            )}
          />
        </>
      )}

      <Outlet />
    </PageContainer>
  );
}
