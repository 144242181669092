import { useTranslation } from 'react-i18next';

import BoxLayout from 'components/BoxLayout';
import Text from 'components/Text';
import { ButtonLink } from 'components/Button';
import Spacer from 'components/Spacer';

export default function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <BoxLayout
      boxSize="wide"
      underElement={
        <ButtonLink
          to="/"
          size="xl"
          variant="white"
          title="buttons.go_to_home"
        />
      }
    >
      <>
        <Text
          variant="section-title"
          centered
        >
          {t('titles.privacy_policy')}
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
        <Spacer />
        <Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. At laboriosam error odit vel similique a labore, magnam veniam molestias repudiandae obcaecati quas quibusdam inventore. Iste itaque, repellendus quod incidunt placeat.
        </Text>
      </>
    </BoxLayout>
  );
}
