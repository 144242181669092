import {
  useNavigate,
  useParams,
  Outlet
} from 'react-router-dom';

import { Tenant } from 'types/tenant';
import { PropertyUnitAgreement } from 'types/property-unit-agreement';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

import { useList } from 'hooks/useList';
import { useItemById } from 'hooks/useItemById';

import Drawer from 'components/Drawer';
import SectionHeader from 'components/SectionHeader';
import List from 'components/List';
import { ItemLink } from 'components/Item';


function TenantOverview({ tenant }: { tenant: Tenant }) {
  const agreementList = useList<PropertyUnitAgreement, any>('property-unit-agreement', {
    enabled: !!tenant.name,
    filter: {
      tenant: tenant.name
    }
  });

  return (
    <>
      <SectionHeader
        title={tenant.name}
        variant="dialog"
      />
      <SectionHeader title="titles.property_unit_agreements" />
      <List
        isLoading={agreementList.isLoading}
        error={agreementList.error?.message}
        data={agreementList.data}

        layout="vertical"

        item={({ item }) => (
          <ItemLink
            key={item.id}

            to={`/units/${item.propertyUnitId}/agreement/${item.id}`}

            title={`${formatDate(item.startingOn)} - ${item.endingOn ? formatDate(item.endingOn) : 'OPEN'}`}
            titleIcon="License"

            attributes={[
              [item.propertyUnit?.property?.type === 'full' ? 'BuildingCommunity' : 'Building', item.propertyUnit?.property?.name ??  ''],
              [item.propertyUnit?.type === 'commercial' ? 'BuildingStore' : 'Home', item.propertyUnit?.name ??  ''],
              ['CurrencyEuro', formatMoney(item.price)]
            ]}

            isOld={item.startingOn && item.endingOn && (new Date(item.endingOn).valueOf() < new Date().valueOf()) ? true : false}
          />
        )}
      />
    </>
  );
}

export default function TenantDetail() {
  const navigate = useNavigate();
  const { tenantId } = useParams();

  const { isLoading, data, error } = useItemById<Tenant>('tenant', tenantId);

  const dismiss = () => {
    navigate('..');
  };

  return (
    <Drawer
      isOpen={true}
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      {data && <TenantOverview tenant={data} />}
      <Outlet />
    </Drawer>
  );
}
