import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PropertyUnit } from 'types/property-unit';
import { CreatePropertyUnitAgreement, PropertyUnitAgreement } from 'types/property-unit-agreement';

import { useItemById } from 'hooks/useItemById';
import { useCreateItem } from 'hooks/useCreateItem';

import { FormTextField } from 'components/TextField';
import { FormSwitch } from 'components/Switch';
import { FormDateField } from 'components/DateField';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyUnitAgreementCreate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading, error } = useItemById<PropertyUnit>('property-unit', id);

  const createItem = useCreateItem<CreatePropertyUnitAgreement, PropertyUnitAgreement>('property-unit-agreement', {
    onSuccess: () => {
      toast.success('Agreement added!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    createItem.reset();
    navigate('..');
  };

  const form: CreatePropertyUnitAgreement = {
    propertyUnitId: id || 'nope',

    tenant: '',
    allowTenantAccess: true,
    price: '',
    startingOn: '',
    endingOn: ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_unit_agreement_create"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="CreatePropertyUnitAgreement"
        error={createItem.error?.message}
        onSubmit={async (values) => createItem.mutate(values as CreatePropertyUnitAgreement)}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.create"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormTextField
          name="tenant"
          type="text"
          label="fields.tenant"
        />
        <FormSwitch
          name="allowTenantAccess"
          label="fields.allow_tenant_access"
        />
        <FormTextField
          name="price"
          type="text"
          label="fields.price"
        />
        <FormDateField
          name="startingOn"
          label="fields.starting_on"
        />
        <FormDateField
          name="endingOn"
          label="fields.ending_on"
          clearable
        />
      </Form>
    </Modal>
  );
}
