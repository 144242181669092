import * as Yup from 'yup';

export const Login = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(),
  password: Yup.string()
    .trim()
    .required()
});

export const Register = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required(),
  lastName: Yup.string()
    .trim()
    .required(),
  email: Yup.string()
    .trim()
    .required(),
  password: Yup.string()
    .trim()
    .required(),
  language: Yup.string()
    .trim()
    .oneOf(['en', 'de', 'es'])
    .required()
});

export const Recover = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required()
});

export const UpdatePassword = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required(),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'invalid').required()
    .trim()
    .required()
});

export const UpdateAccount = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required(),
  lastName: Yup.string()
    .trim()
    .required(),
  language: Yup.string()
    .trim()
    .oneOf(['en', 'de', 'es'])
    .required()
});

export const UpdateAccountAvatar = Yup.object().shape({
  avatar: Yup.mixed()
    .required()
});

export const UpdateAccountEmail = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required()
});

export const UpdateAccountPassword = Yup.object().shape({
  currentPassword: Yup.string()
    .trim()
    .required(),
  newPassword: Yup.string()
    .trim()
    .required()
});



export const CreateProperty = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  type: Yup.string()
    .trim()
    .oneOf(['partial', 'full'])
    .required(),

  addressStreet: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .trim()
    .required(),

  addressPostalCode: Yup.number()
    .positive('Must be positive')
    .integer('Must be a number')
    .required(),

  addressCity: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .trim()
    .required(),

  addressCountry: Yup.string()
    .length(2, 'Invalid! ie: DE, RO')
    .trim()
    .required()
});


export const UpdateProperty = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  type: Yup.string()
    .trim()
    .oneOf(['partial', 'full'])
    .required(),

  addressStreet: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .trim()
    .required(),

  addressPostalCode: Yup.number()
    .positive('Must be positive')
    .integer('Must be a number')
    .required(),

  addressCity: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .trim()
    .required(),

  addressCountry: Yup.string()
    .length(2, 'Invalid! ie: DE, RO')
    .trim()
    .required()
});


export const CreatePropertyUnit = Yup.object().shape({
  propertyId: Yup.string()
    .required(),

  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  type: Yup.string()
    .trim()
    .oneOf(['apartment', 'commercial'])
    .required(),

  description: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .trim(),

  bedrooms: Yup.number()
    .min(0, 'Must be positive'),

  bathrooms: Yup.number()
    .min(0, 'Must be positive'),

  area: Yup.number()
    .min(0, 'Must be positive'),

  floor: Yup.number()
    .min(0, 'Must be positive')
});

export const UpdatePropertyUnit = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  type: Yup.string()
    .trim()
    .oneOf(['apartment', 'commercial']),

  description: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .trim(),

  bedrooms: Yup.number()
    .min(0, 'Must be positive'),

  bathrooms: Yup.number()
    .min(0, 'Must be positive'),

  area: Yup.number()
    .min(0, 'Must be positive'),

  floor: Yup.number()
    .min(0, 'Must be positive')
});

export const CreatePropertyUnitMeter = Yup.object().shape({
  propertyUnitId: Yup.string()
    .required(),

  tenant: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  price: Yup.string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .trim(),

  startingOn: Yup.date()
    .required(),

  endingOn: Yup.date()
});

export const UpdatePropertyUnitMeter = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  unit: Yup.string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .trim(),
});


export const CreatePropertyUnitMeterReading = Yup.object().shape({
  propertyUnitMeterId: Yup.string()
    .required(),

  happenedOn: Yup.date()
    .required(),

  value: Yup.number()
    .required(),

  agent: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
});

export const UpdatePropertyUnitMeterReading = Yup.object().shape({
  happenedOn: Yup.date(),

  value: Yup.number(),

  agent: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
});

export const CreatePropertyUnitAgreement = Yup.object().shape({
  propertyUnitId: Yup.string()
    .required(),

  tenant: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  price: Yup.string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .trim(),

  startingOn: Yup.date()
    .required(),

  endingOn: Yup.date()

});

export const UpdatePropertyUnitAgreement = Yup.object().shape({
  tenant: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  price: Yup.string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .trim(),

  startingOn: Yup.date()
    .required(),

  endingOn: Yup.date()
});

export const CreatePropertyUnitCost = Yup.object().shape({
  propertyUnitId: Yup.string()
    .required(),

  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  price: Yup.string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .trim(),

  payer: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  payee: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  happenedOn: Yup.date()
});

export const UpdatePropertyUnitCost = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  price: Yup.string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .trim(),

  payer: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  payee: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  happenedOn: Yup.date()
});


export const CreatePropertyContact = Yup.object().shape({
  propertyId: Yup.string()
    .required(),

  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  description: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .trim()
    .required(),

  email: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim(),

  phone: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
});

export const UpdatePropertyContact = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
    .required(),

  description: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .trim()
    .required(),

  email: Yup.string()
    .trim()
    .required(),

  phone: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .trim()
});

export const CreateDirectory = Yup.object().shape({
  name: Yup.string()
    .min(2)
    .max(100)
    .trim()
    .required()
});

export const Rename = Yup.object().shape({
  name: Yup.string()
    .min(2)
    .max(100)
    .trim()
    .required()
});

export const CreateChatConversation = Yup.object().shape({
  recipientId: Yup.string()
    .required()
});

