import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UpdatePropertyUnitMeterReading, PropertyUnitMeterReading } from 'types/property-unit-meter-reading';

import { useItemById } from 'hooks/useItemById';
import { useUpdateItem } from 'hooks/useUpdateItem';

import { FormTextField } from 'components/TextField';
import { FormDateField } from 'components/DateField';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyUnitMeterReadingUpdate() {
  const navigate = useNavigate();
  const { readingId } = useParams();

  const { isLoading, data, error } = useItemById<PropertyUnitMeterReading>('property-unit-meter-reading', readingId);

  const update = useUpdateItem<UpdatePropertyUnitMeterReading, PropertyUnitMeterReading>('property-unit-meter-reading', readingId, {
    onSuccess: () => {
      toast.success('Saved!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    update.reset();
    navigate('..');
  };

  const form: UpdatePropertyUnitMeterReading = {
    happenedOn: data?.happenedOn || '',
    value: data?.value,
    agent: data?.agent || ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_unit_meter_reading_update"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="UpdatePropertyUnitMeterReading"
        error={update.error?.message}
        onSubmit={async (values) => update.mutate(values as UpdatePropertyUnitMeterReading)}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.save"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormDateField
          name="happenedOn"
          label="fields.happened_on"
        />
        <FormTextField
          name="value"
          label="fields.value"
          type="text"
        />
        <FormTextField
          name="agent"
          label="fields.agent"
          type="text"
        />
      </Form>
    </Modal>
  );
}
