import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PropertyUnit } from 'types/property-unit';
import { CreatePropertyUnitMeterReading, PropertyUnitMeterReading } from 'types/property-unit-meter-reading';

import { useItemById } from 'hooks/useItemById';
import { useCreateItem } from 'hooks/useCreateItem';

import { FormTextField } from 'components/TextField';
import { FormDateField } from 'components/DateField';
import { FormSelectField } from 'components/SelectField';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';


export default function PropertyUnitMeterReadingCreate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading, error } = useItemById<PropertyUnit>('property-unit', id);

  const createItem = useCreateItem<CreatePropertyUnitMeterReading, PropertyUnitMeterReading>('property-unit-meter-reading', {
    onSuccess: () => {
      toast.success('Reading added!');
      dismiss();
    },
    onError: () => {
      toast.error('Something went wrong!');
    }
  });

  const dismiss = () => {
    createItem.reset();
    navigate('..');
  };

  const form: CreatePropertyUnitMeterReading = {
    // propertyUnitMeterId: '57a643e9-e002-4c93-8572-f12fc46f98c7',
    propertyUnitMeterId: '',
    happenedOn: '',
    value: 0,
    agent: ''
  };

  return (
    <Modal
      isOpen={true}
      size="sm"
      onDismiss={dismiss}

      isLoading={isLoading}
      error={error?.message}
    >
      <SectionHeader
        title="titles.property_unit_meter_reading_create"
        variant="dialog"
      />
      <Form
        initialValues={form}
        validationSchema="CreatePropertyUnitMeterReading"
        error={createItem.error?.message}
        onSubmit={async (values) => createItem.mutate(values as CreatePropertyUnitMeterReading)}
        submitElement={(isSubmitting) => (
          <Button
            type="submit"
            variant="primary"
            size="xl"
            title="buttons.create"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      >
        <FormSelectField
          name="propertyUnitMeterId"
          label="fields.meter"
          optionsName="property-unit-meter"
          optionsPayload={{
            propertyUnitId: id
          }}
          allowCreate={{
            propertyUnitId: id,
            unit: 'unknown'
          }}
        />
        <FormDateField
          name="happenedOn"
          label="fields.happened_on"
        />
        <FormTextField
          name="value"
          label="fields.value"
          type="text"
        />
        <FormTextField
          name="agent"
          label="fields.agent"
          type="text"
        />
      </Form>
    </Modal>
  );
}
