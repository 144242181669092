import { useTranslation } from 'react-i18next';

import DamnLayout from 'components/DamnLayout';
import Text from 'components/Text';
import Button from 'components/Button';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';

interface Props {
  isOpen?: boolean;
  isLoading?: boolean;
  onDismiss: () => void;
  onSubmit: () => void;
}

export default function EntityDelete(props: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={props.isOpen}
      size="sm"
      onDismiss={() => props.onDismiss()}
    >
      <SectionHeader
        title="titles.file_manager_entity_delete"
        variant="dialog"
      />
      <DamnLayout variant="vertical">
        <Text centered>
          {t('confirmations.file_manager_entity_delete')}
        </Text>
        <Button
          type="submit"
          variant="danger"
          size="xl"
          title="buttons.delete"
          loading={props.isLoading}
          disabled={props.isLoading}
          onPress={() => props.onSubmit()}
        />
      </DamnLayout>
    </Modal>
  );
}
