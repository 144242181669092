import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import {
  Node,
  NodeList,
  Feature
} from '../types';

import styles from './Body.module.scss';

import File from 'components/icons/File';
import Folder from 'components/icons/Folder';

interface Props {
  structure: NodeList;
  loading: boolean;

  currentPath: string;
  setCurrentPath: (path: string) => void;

  selection?: string[];
  setSelection: (paths: string[]) => void;

  doRename: () => void;
  doUpload: (path: string, files: File[]) => void;
  doOpenFile: (file: Node) => void;

  enabledFeatures: Feature[];
}

export default function Body(props: Props) {
  const list = props.structure[props.currentPath] || [];

  const { t } = useTranslation();

  const isEmpty = !props.loading && !list.length;

  return (
    <Dropzone
      onDropAccepted={(dropped) => {
        props.doUpload(props.currentPath, [dropped[0]]);
      }}
      noClick
      noKeyboard
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({ className: `${styles.base} ${isEmpty ? styles.isEmpty : '' }` })}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            props.setSelection([]);
          }}
        >
          {list.length ? (
            <>
              {list.map((item, index) => {
                const path = item.path;
                const selected = props.selection?.includes(path);
                return (
                  <div
                    key={index}
                    className={`${styles.item} ${item.type === 1 ? styles.itemLeaf : styles.itemNode} ${selected ? styles.itemSelected : ''}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      props.setSelection(props.selection && props.selection[0] === item.path ? [] : [item.path]);
                    }}
                    onDoubleClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      props.setSelection([]);
                      if (item.type === 1) {
                        props.doOpenFile(item);
                      } else {
                        props.setCurrentPath(`${props.currentPath}/${item.name}`);
                      }
                    }}
                  >
                    <div className={styles.itemItem}>
                      {item.type === 1 ? <File /> : <Folder />}
                    </div>
                    <div
                      className={styles.itemName}
                      title={item.name}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (!props.selection || props.selection[0] !== path) {
                          return;
                        }
                        if (!props.enabledFeatures.includes('rename')) {
                          return;
                        }
                        if (window.getSelection()?.toString().length) {
                          return;
                        }
                        props.doRename();
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </>
          ) : isEmpty ? (
            <div className={styles.empty}>
              {t('text.empty')}
            </div>
          ) : null}
        </div>
      )}
    </Dropzone>
  );
}
